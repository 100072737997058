<template>
    <v-card v-if="ready">

      <v-toolbar dark :color="line_color">
  
        <v-toolbar-title>Specifications</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn light
          @click="save"
          :disabled="unchanged">
          Save
        </v-btn>
      </v-toolbar>

      <v-container fluid>
        <v-row>
          <v-col>
            <v-autocomplete
              v-model="category_name"
              :items="names"
              label="Category"
              class="text-h5"
              @click="updateCategories"
              @change="updateCategory"
            ></v-autocomplete>
            <div class="text-subtitle-1" style="margin-top:-20px; margin-bottom:10px">
              {{category_breadcrumb_string}}
            </div>

            <v-text-field 
              label="Desciption"
              class="text-h5"
              @input="unchanged=false"
              v-model="component_selection.short_description"
            ></v-text-field>

            <WYSIWYG @input="unchanged=false" v-model="component_selection.description" style="margin-top:-15px; background-color:whitesmoke">                  
            </WYSIWYG>  
  
          </v-col>
        </v-row>
      </v-container>      
      <v-toolbar dark :color="line_color">
  
        <v-toolbar-title>Selected component</v-toolbar-title>

        <v-spacer></v-spacer>

        <a
          v-if="component_selection.component" 
          :href="this.$directus.transport.url + '/admin/collections/component/' + component_selection.component.id"
          target="_blank"        
        >
          <v-btn light>
            Details
          </v-btn>
        </a>

        <tabular-selector
              v-if="selectable_components" 
              :disabled="!unchanged"
              :color="line_color"                      
              :items="selectable_components"
              picture_name="main_picture"
              :fields="['main_picture','internal_code','category.name','title']"
              :select="true"
              @itemSelected="saveComponentSelection"
              @selectionOpened.capture="updateSelectableComponents"
              >
            <template v-slot:title >
                Select the component
            </template>   
        </tabular-selector>    

        <a
          :href="this.$directus.transport.url + '/admin/collections/component/+'"
          target="_blank"        
        >
          <v-btn light>
            New component
          </v-btn>
        </a>

      </v-toolbar>

      <v-card
          class="mx-auto"
          v-if="component_selection.component"
        >
          
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">
                {{component_selection.component.category.name}}
              </div>
              <v-list-item-title class="headline mb-1">
                {{component_selection.component.title}}
              </v-list-item-title>
              <v-list-item-subtitle v-if="component_selection.component.internal_code">
                internal_code: {{component_selection.component.internal_code}}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="component_selection.component.external_code">
                external_code: {{component_selection.component.external_code}}
              </v-list-item-subtitle>

            </v-list-item-content>

              <!--v-img contain
                :aspect-ratio="16/9" 
                height="200px" 
                v-if="component_selection.component && component_selection.component.main_picture" 
                :src="$directus.transport.url + '/assets/' + component_selection.component.main_picture.id + '?access_token=' + $directus.auth.token ">
              </v-img-->
              <exandable-image
                v-if="component_selection.component && component_selection.component.main_picture" 
                :image_id="component_selection.component.main_picture.id"
                height="200px"
              >
              </exandable-image>
   
          </v-list-item>

          <v-list-item>
            <a :href="component_selection.component.product_page" target="_blank">Product page</a>
          </v-list-item>

          <v-container fluid>
            <v-row>
              <v-col>
                <WYSIWYG read_only v-model="component_selection.component.description" style="margin-top:-15px">                  
                </WYSIWYG>  
              </v-col>
            </v-row>
          </v-container>

        </v-card>
    </v-card>
</template>

<script>
import ExandableImage from './ExandableImage.vue';
import TabularSelector from './TabularSelector.vue';
import WYSIWYG from './WYSIWYG.vue';
export default {
  components: { WYSIWYG, TabularSelector, ExandableImage },

  data : () => {
    return {
      component_selection: {"category":{"name":""}, "description":"", "component": {}},
      ready: false,
      categories: [],
      names: [],
      category_name: "",
      category_breadcrumb_string: "",
      unchanged: true,
      selectable_components: [],
    }
  },

  props: {
    item_id: {
      required: false,
      default: null,
    },
    line_color: {
      type: String,
      required: false,
      default: "blue",
    },

  },

  async mounted() {    
    await this.update();    
    this.updateCategory();   
    // Disable the save button
    this.unchanged = true;
    this.ready = true;
  },

  methods: {
    async save () {
      const component_selections = this.$directus.items('component_selection');    
      await component_selections.updateMany([{
          id: this.component_selection.id,
          short_description:this.component_selection.short_description,
          description:this.component_selection.description,
          category:this.component_selection.category,
        }],
        { 
          fields: ['short_description',"description","category"]
        }
      );
      // Disable the save button
      this.unchanged = true;
      this.$emit("component-selection-modified",this.component_selection);
    },
    async update () {
      // Update the component selection
      const component_selections = this.$directus.items('component_selection');      
      this.component_selection = await component_selections.readOne(this.item_id, { 
        fields: ["id", "quantity", "short_description","description",
                 "category.id","category.name",
                 "component.id","component.title",
                 "component.internal_code","component.external_code",
                 "component.category.id","component.category.name",
                 "component.discontinued","component.product_page",
                 "component.description",
                 "component.main_picture.id"] });
      //console.log(this.component_selection);
      if (!this.component_selection.category){
        this.component_selection.category = {"name": ""};  
        this.category_name = "";
        this.category_breadcrumb_string = "";
      }
      else{
        this.category_name = this.component_selection.category.name;
      }
      await this.updateCategories();
    },
    async updateCategories() {
      // Update the list of the categories
      const categories = this.$directus.items('component_category');
      let result = await categories.readMany({fields: ["id","name","parent.id","sub_categories"], limit:-1 }); 
      //console.log(result);
      this.categories = result.data;
      this.names = Array.from(this.categories, item => item.name);      
    },
    async updateCategory() {
      // Update this.component_selection.category according to this.category_name
      if (this.categories.length > 0){
        let newCategory = this.categories.find(category => category.name == this.category_name);
        if (newCategory) {
          this.component_selection.category = newCategory;
        }
        else {
          return;
        }
      }
      if (this.component_selection.category && this.component_selection.category.id && this.component_selection.category.name) {
        // Update the breadcrumb
        let category = this.component_selection.category; 
        this.category_breadcrumb_string = category.name;        
        while (category.parent && category.parent.id){
          category = this.categories.find(other_category => other_category.id == category.parent.id);
          if (category && category.name && category.name != "Component") {
            this.category_breadcrumb_string = category.name + "->" + this.category_breadcrumb_string;            
            //console.log(this.category_breadcrumb_string);
          }
          else{
            break;
          }
        }        
        // Update the selectable components
        await this.updateSelectableComponents();
        // Enable the save button
        this.unchanged = false;        
      }
      else {
        this.category_breadcrumb_string = "";
      }
    },

    // Hierarchically returns the set of the passed id and of the ids its subcategories
    getSubCategories (category_id){
      var result = [category_id];
      let parent_category = this.categories.find(category => category.id == category_id);
      if (parent_category) {
        var i;
        for (i = 0; i < parent_category.sub_categories.length; i++) {
          result = result.concat(this.getSubCategories(parent_category.sub_categories[i]));
        }        
      }
      return result;
    },

    // Select only the components that belong to the category of the specification or one of its subcaterogies
    async updateSelectableComponents(){

        if (!this.component_selection.category || this.categories.length < 1){
          this.selectable_components = null;
        }

        // Get the sub-categories of the category of the specification
        let allowed_categories = this.getSubCategories(this.component_selection.category.id);
        if (allowed_categories.length == 0){
          this.selectable_components = [];          
        }
        else{
          // Get all the components that belong to this categories
          const components = this.$directus.items('component');   
          let result = await components.readMany({
              fields:["id","main_picture","category.name","internal_code","title"],
              filter: {
                "category": {
                  "_in": allowed_categories,
                },
              },
              limit:-1,
          });

          this.selectable_components = result.data;
        }
    },    

    async saveComponentSelection (component_id){
      // Save into the db
      const component_selections = this.$directus.items('component_selection');          
      await component_selections.updateMany([{
          id: this.component_selection.id,
          component:component_id,
        }],
        { 
          fields: ['component']
        }
      );
      // Update the view
      await this.update();
      this.$emit("component-selection-modified",this.component_selection);
    }
  },

  computed: {
  },

  watch : {
    item_id: async function(){
      await this.update();
      await this.updateCategory();
      // Disable the save button
      this.unchanged = true;   
    },
  },

}
</script>

<style>
  .v-card__title input:focus {
      outline: none;
  }

</style>