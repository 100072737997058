var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"hide-overlay":"","max-width":"60%","origin":"right right","transition":"slide-x-reverse-transition","content-class":"right-dialog"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.add)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":_vm.color,"disabled":_vm.disabled}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-playlist-plus")])],1):_c('v-btn',_vm._g(_vm._b({attrs:{"light":"","disabled":_vm.disabled}},'v-btn',attrs,false),on),[_c('span',[_vm._v(" Select ")])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":_vm.color}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._t("title")],2)],1),_c('v-list',{attrs:{"three-line":"","subheader":""}},[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search},scopedSlots:_vm._u([{key:_vm.item_dot_picture,fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{attrs:{"rounded":"","size":_vm.image_width,"color":"transparent"}},[_c('v-img',{attrs:{"max-width":_vm.image_width,"src":_vm.$directus.transport.url + '/assets/' + item[_vm.picture_name] + '?access_token=' + _vm.$directus.auth.token + '&quality=100&width=' + _vm.image_width}})],1)]}},(_vm.add)?{key:"item.add",fn:function(ref){
var item = ref.item;
return [_c('v-btn',_vm._b({attrs:{"icon":"","color":_vm.color},on:{"click":function($event){return _vm.addItem(item[_vm.id_name])}}},'v-btn',_vm.attrs,false),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}:null,(_vm.select)?{key:"item.select",fn:function(ref){
var item = ref.item;
return [_c('v-btn',_vm._b({attrs:{"color":_vm.color,"dark":""},on:{"click":function($event){return _vm.selectItem(item[_vm.id_name])}}},'v-btn',_vm.attrs,false),[_vm._v(" choose ")])]}}:null],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }