<template>
  
    <v-list > 

        <v-list-item style="margin-left:13px">          
            <v-list-item-content>
            <v-list-item-title class="font-weight-bold">
                <!--v-icon :color="line_color">
                mdi-circle
                </v-icon-->                 
                {{numberOfComponents}} Components

                <v-btn icon
                    :color="line_color" 
                    @click="show_details=!show_details"
                    v-if="numberOfComponents>0">
                    <v-icon v-show="show_details">mdi-chevron-up</v-icon>
                    <v-icon v-show="!show_details">mdi-chevron-down</v-icon>
                </v-btn>

                <v-btn icon :color="line_color" @click="createComponentSelection()">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>

            </v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <draggable
                v-model="production_bom.selected_components"                     
                group='components'
                delay="50"
                :sort="false"            
                :move="onMoveComponent"
                style="border-left: 2px solid"
                :style="{borderLeftColor:line_color}"
                class="ml-8" 
                :id="production_bom.id"
                handle=".handle"
        >
                   <component-line v-for="component_selection in componentsToShow"
                        :key="component_selection.id"
                        :component_selection="component_selection"
                        :image_width="50"
                        @componentSelectionRemoved="removeComponentSelection(component_selection)"
                        @componentSelectionCloned="cloneComponentSelection(component_selection)"
                    >         
                    </component-line>
        </draggable>

    </v-list>

</template>


<script>

import draggable from "vuedraggable";
import ComponentLine from "./ComponentLine.vue";

export default {

  name: "components",

  components: {
    ComponentLine,
    draggable,
  },

  data : () => {
    return {
      show_details: false,
    }
  },

  props: {
    production_bom: {
      type: Object,
      required: true,
    },
    line_color: {
      type: String,
      required: false,
      default: "blue",
    },
    open_all: {
      type: Boolean,
      required: true,
    },
    close_all: {
      type: Boolean,
      required: true,
    },

  },

  watch : {
    'open_all': function(){
      this.show_details = true;
    },
    'close_all': function(){
      this.show_details = false;
    }

  },

  computed: {
     numberOfComponents () {
      let count = 0;
      if (!this.production_bom.selected_components){
        return 0;
      }
      this.production_bom.selected_components.forEach(element => {
        count+=element.quantity;
      });
      return count;
    },
    componentsToShow(){
        if (this.show_details){
            return this.production_bom.selected_components;
        }
        else{
            return [];
        }
    }
  },

  methods: {  
    async createComponentSelection(){
      // Create the new component selection
      const component_selections = this.$directus.items('component_selection');    
      let new_component_selection = await component_selections.createOne({
        quantity: 1,
        production_bom: this.production_bom.id,
      });
      // Add to the list of the component selections
      this.production_bom.selected_components.push(new_component_selection);
      this.$componentSelectionMap.set(new_component_selection.id, new_component_selection);
      // Route to the corresponding page
      this.$router.push({ name: 'BOM', params: { bom_id: this.$route.params.bom_id, collection: 'component_selection', item_id: new_component_selection.id}})
    },

    onMoveComponent({ draggedContext, to }) {
      /*if (!window.confirm("Move component selection " + draggedContext.element.short_description + "?")){        
        return false; 
      }*/
      console.log("Moving component selection " + draggedContext.element.id);
      console.log("to " + to.id);
      const component_selections = this.$directus.items('component_selection');    
      component_selections.updateMany([{
          id: draggedContext.element.id,
          production_bom: to.id,
        }],
        { 
          fields: ['production_bom'] 
        }
      );
      return true;
    },
    
    async removeComponentSelection (component_selection){
      // Remove from the database
      const component_selections = this.$directus.items('component_selection');    
      try {
        await component_selections.deleteOne(component_selection.id);
        // Update the vue
        let index = this.production_bom.selected_components.indexOf(component_selection);
        if (index>=0){
          this.production_bom.selected_components.splice(index,1);
        }
      }
      catch{
        alert("Error removing component selection with id " + component_selection.id);
      }
      // Move to empty page if the current item_id is the one of the removed component selection
      if (this.$route.params.collection=="component_selection" && this.$route.params.item_id == component_selection.id){
        this.$router.push({ name: 'BOM', params: { bom_id:  this.$route.params.bom_id, collection: 'none', item_id:'-1'  }});
      }
    },

    async cloneComponentSelection(component_selection){

      // Get all the required fields
      const component_selections = this.$directus.items('component_selection');    
      let to_copy = await component_selections.readOne(component_selection.id, { 
        fields: ["id", "quantity", "short_description","description","category", "component"]});

      // Create the new componet selection
      let new_component_selection = await component_selections.createOne({
          quantity:to_copy.quantity,
          description: to_copy.description,
          short_description: to_copy.short_description,
          category: to_copy.category,
          component: to_copy.component,
          production_bom: this.production_bom.id,
        }
      );

      // Update the view
      let component_selection_copy =  Object.assign({}, component_selection);
      component_selection_copy.id = new_component_selection.id;
      this.production_bom.selected_components.push(component_selection_copy);
    }
  },


}
</script>

<style>
</style>