<template>
  <v-card v-if="ready">
    <h1>List of the components</h1>

    <v-list v-for="[producer_name, producer_component_map] in component_map" :key="producer_name" two-line>

      <h2>{{producer_name}}</h2>

      <v-list-item v-for="[component_id, component] in producer_component_map" :key="component_id">

        <v-list-item-avatar
            rounded
            :size="image_width"
            color="transparent"
        >
            <v-img contain :max-width="image_width" v-if="component && component.main_picture" :src="$directus.transport.url + '/assets/' + component.main_picture.id + '?access_token=' + $directus.auth.token + '&quality=100&width=' + image_width"></v-img>
        </v-list-item-avatar>

        <v-list-item-content >
          
          <v-list-item-title >

            <span>
              {{component.quantity}} x
            </span>  

            <span>
              {{component.category.name}} - 
              <strong>{{component.internal_code}}</strong>
            </span>

            <a
              :href="$directus.transport.url + '/admin/collections/component/' + component.id"
              target="_blank"    
              class="text-decoration-none black--text"         
            >
            <v-btn text outlined small>Details</v-btn>
            </a>           

            <a
              v-if="component.product_page"
              :href="component.product_page"
              target="_blank"    
              class="text-decoration-none black--text"         
            >
              <v-btn 
                text 
                small
                outlined>
                Product page
              </v-btn>
            </a>                       

          </v-list-item-title >

          <v-list-item-subtitle>
            {{component.title}}
          </v-list-item-subtitle>

        </v-list-item-content>

          <div v-for="cost in component.cost" :key="cost.id" >
            <div class="cost_button">
              <strong> {{cost.unit_cost}} <span v-if="cost.unit_import_costs>0"> + {{cost.unit_import_costs}} </span> <span v-if="cost.shipping_costs>0"> + {{cost.shipping_costs}} </span><span v-if="cost.currency">{{cost.currency.symbol}}</span> </strong>
              <cost-formula-dialog
                image_height=50
                :component="component" 
                :fields="cost"
                @costs_changed="forceUpdate">
                <v-icon >mdi-file-edit</v-icon>  
              </cost-formula-dialog>
              <div class="text-caption" v-if="cost.minimim_quantity >= cost.order_unit" >from {{cost.minimim_quantity}} units by {{cost.supplier.name}}</div>
              <div class="text-caption" v-else > {{cost.order_unit}} units package by {{cost.supplier.name}}</div>
            </div>

          </div>
          
          <cost-formula-dialog
           image_height=50
           :component="component" 
           :fields="add_cost_fields"
           @costs_changed="forceUpdate">
            <v-icon>mdi-plus</v-icon>  
          </cost-formula-dialog>

      </v-list-item>
    </v-list>
  </v-card>
</template>


<script>

import { recursiveCreateComponentMap } from '../scripts/recursiveReadBom';
import CostFormulaDialog from './CostFormulaDialog.vue';

export default {

  props: {
    production_bom: {
      required: true,
    },
    image_width: {
      type: Number,
      required: false,
      default: 50,
    }    
  },

  components: {
    CostFormulaDialog,
  },

  data : () => {
    return {
      component_map: null, 
      ready: false,
      add_cost_fields: {      
        minimim_quantity:1,
        order_unit:1,
        unit_cost:0,
        unit_import_costs:0,
        shipping_costs:0,        
        currency: {id:1},
        supplier: {id:null},
        order_link:"",
      },   
    }
  },

  async mounted() {    
    await this.update();
    this.ready = true;
  },

  watch : {
    production_bom: async function(){
      this.update();
    },
  },

  methods: {

    update () {
        this.component_map = new Map();
        recursiveCreateComponentMap(this.production_bom, this.component_map);
    },
    forceUpdate(){
      this.$forceUpdate();
    }

  },

}
</script>

<style>
.cost_button {
  border:1px black solid;
  margin: 5px;
  border-radius: 5px;
  padding: 5px
}
</style>