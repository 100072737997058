<template>
    <div>
        <v-container fluid fill-height>
            <v-layout align-center justify-center>
               <v-flex xs12 sm8 md4>
                  <v-card class="elevation-12">

                        <v-toolbar dark color="primary">
                            <v-toolbar-title>Login</v-toolbar-title>
                        </v-toolbar>
                        <v-form  @submit.prevent="login">
                            <v-card-text>

                                    <v-text-field
                                        v-model="username"
                                        name="login"
                                        label="Login"
                                        type="text"
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="password"
                                        id="password"
                                        name="password"
                                        label="Password"
                                        type="password"
                                    ></v-text-field>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn type="submit" color="primary" >Login</v-btn>
                            </v-card-actions>
                        </v-form>

                   </v-card>
               </v-flex>
            </v-layout>
         </v-container>

    </div>
</template>

<script>

export default {

  data : () => {
    return {
      username: null,
      password: false,
    }
  },
  

  props: {
  },

  methods: {
    login: async function () {  

        await this.$directus.auth.login(
        {
            email: this.username,
            password: this.password,
        },
        {
            refresh: {
            auto: true,
            time: 15000
            },
        }
        );
        
        console.log("emitting the loggedin event")
        
        this.$emit('loggedin');
        
    },
  },

  async mounted() {

        if (this.$directus.auth.token){
            try {
                await this.$directus.auth.refresh();
                this.$emit('loggedin');
            }
            catch {
                console.log(this.$directus);
                console.log("error in loggin in");

            }
        }
  }



}

</script>

<style>

</style>