<template>
  <v-dialog
    v-model="visible"
    persistent
    max-width="800px"
>
    <template v-slot:activator="{ on, attrs }">
    <v-btn
        class="d-print-none" 
        icon color="gray"
        v-bind="attrs"
        v-on="on"
    >
        <slot></slot>
    </v-btn>
    </template>
    <v-card>
    <v-card-title>
        <v-img
         contain
         :max-height="image_height" 
         :max-width="image_height*16/9"
         v-if="component && component.main_picture" 
         :src="$directus.transport.url + '/assets/' + component.main_picture.id + '?access_token=' + $directus.auth.token + '&quality=100&height=' + image_height">
        </v-img>

        <span class="text-h5">
            {{component.internal_code}}
        - Add cost formula 
        </span>

    </v-card-title>
    <v-card-text>
        <v-container>
        <v-row>
            <v-col>
            <v-text-field
                v-model="fields.minimim_quantity"
                label="Minimum quantity"
                type="number"
                min="1"
            ></v-text-field>
            </v-col>
            <v-col>
            <v-text-field
                v-model="fields.order_unit"
                label="Order unit: the quantity to order must be a multiple"
                type="number"
                min="1"
            ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
            <v-text-field
                v-model="fields.unit_cost"
                label="Unit cost"
                type="number"
                min="0"
                step="0.01"
            ></v-text-field>
            </v-col>
            <v-col>
            <v-text-field
                v-model="fields.unit_import_costs"
                label="Unit import costs"
                type="number"
                min="0"
                step="0.01"
            ></v-text-field>
            </v-col>
            <v-col>
            <v-text-field
                v-model="fields.shipping_costs"
                label="Shipping costs"
                type="number"
                min="0"
                step="0.01"
            ></v-text-field>
            </v-col>
            <v-col>
            <v-select
                :items="currencies"
                v-model="fields.currency.id"
                label="Currency"
                outlined
            ></v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
            <v-autocomplete
                v-model="fields.supplier.id"
                :items="suppliers"
                label="Supplier"
                class="text-h5"
                @click="updateSuppliers"
            ></v-autocomplete>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
            <v-text-field
                v-model="fields.order_link"
                label="Order link"
            ></v-text-field>
            </v-col>
            <v-col cols="auto">
              <a
                v-if="fields.order_link && fields.order_link!=''"
                :href="fields.order_link"
                target="_blank"     
                class="text-decoration-none black--text"     
              >
                <v-btn 
                text 
                outlined
                style="margin-top:20px" color="blue darken-1">   
                  GO
                </v-btn>
              </a>
            </v-col>
        </v-row>
        </v-container>
    </v-card-text>
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
        color="blue darken-1"
        text
        outlined
        @click="visible = false"
        >
        Close
        </v-btn>

        <v-btn
         v-if="fields.id"
         text 
         outlined
         color="red darken-1" 
         @click="removeCost(fields.id, component)" 
         style="position:relative">
          Remove
        </v-btn>

        <a
          v-if="fields.id"
          :href="$directus.transport.url + '/admin/collections/cost_formula/' + fields.id"
          target="_blank"     
          class="text-decoration-none black--text"     
        >
          <v-btn text outlined color="blue darken-1">   
            Details
          </v-btn>
        </a>

        <v-btn
        color="blue darken-1"
        text
        outlined
        @click="save(component)"
        >
        Save
        </v-btn>
    </v-card-actions>
    </v-card>
</v-dialog>              
</template>

<script>
export default {

  props: {
    component: {
      required: true,
    },
    fields: {
      required: true,
    },
    image_height: {
      required: false,
      default: 50,
    },
  },

  data : () => {
    return {
      visible: false, 
      currencies:[],   
      suppliers:[],
    }
  },

  async mounted() {    
    let result = await this.$directus.items('currency').readMany({fields: ['id','code']} );
    this.currencies = Array.from(result.data, currency => { return {value: currency.id, text: currency.code}});
    this.updateSuppliers();
  },

  methods: {

    async removeCost(cost_id, component){
      if (!window.confirm("Remove this cost formula?")){        
        return; 
      }
      await this.$directus.items('cost_formula').deleteOne(cost_id);
      component.cost = component.cost.filter(cost => cost.id!= cost_id );
      this.$emit("costs_changed");
     },
    async updateSuppliers(){
      let result = await this.$directus.items('supplier').readMany({fields: ['id','name']} );
      this.suppliers = Array.from(result.data, supplier => { return {value: supplier.id, text: supplier.name}});  
    },
    async save(component){
      if (this.fields.supplier){
        let fields = { ...this.fields };
        fields.component = component.id;
        fields.currency = this.fields.currency.id;
        fields.supplier = this.fields.supplier.id;
        let new_cost = null;
        if (!fields.id) {
          new_cost = await this.$directus.items('cost_formula').createOne(fields);  
        }
        else {
          new_cost = {id: fields.id};
          await this.$directus.items('cost_formula').updateMany([fields],
            { 
              fields: [
                'minimim_quantity', 
                'order_unit',
                'unit_cost',
                'unit_import_costs',
                'shipping_costs',
                'currency',
                'supplier',
                'oder_link',
              ]
            });
        }
        new_cost = await this.$directus.items('cost_formula').readOne(new_cost.id,{ fields:[
          'id', 
          'minimim_quantity', 
          'order_unit',
          'unit_cost',
          'unit_import_costs',
          'shipping_costs',
          'currency.id',
          'currency.symbol',
          'supplier.id',
          'supplier.name',
          'order_link',
          ]
        });
        if (!fields.id){
          component.cost.push(new_cost);
        }
        this.visible= false;
        this.$emit("costs_changed");
      }
    },
  },

}
</script>

<style>

</style>