async function recursiveReadOne (collection, bom_id, fields) {
  let item = await collection.readOne(bom_id, { fields: fields });
  for (var element of item.sub_parts){
    element.child = await recursiveReadOne (collection, element.child.id, fields);
  }
  return item;
}

function recursiveSetCosts (production_bom, componentCostMap) {
  production_bom.total_cost = 0;
  for (var element of production_bom.sub_parts){
    recursiveSetCosts(element.child, componentCostMap);
    element.unit_cost = element.child.total_cost;
    element.total_cost = element.quantity * element.unit_cost; 
    production_bom.total_cost += element.total_cost;
  }
  for (var component_selection of production_bom.selected_components){
    if (component_selection.component && componentCostMap.has(component_selection.component.id)){
      component_selection.unit_cost = componentCostMap.get(component_selection.component.id);
      component_selection.total_cost = component_selection.unit_cost*component_selection.quantity;
      production_bom.total_cost+=component_selection.total_cost;
    }
  }
}


async function recursiveReadBom(production_boms, bom_id) {
    return await recursiveReadOne(production_boms, bom_id, [
    'id',
    'internal_code',
    'external_code',
    'picture.id',
    'description',
    'selected_components.id',
    'selected_components.quantity',
    'selected_components.short_description', 
    'selected_components.component.id',
    'selected_components.component.title',
    'selected_components.component.internal_code',
    'selected_components.component.category.name',
    'selected_components.component.main_picture.id',
    'selected_components.component.producer.name',
    'selected_components.component.product_page',
    'selected_components.component.cost.id', 
    'selected_components.component.cost.minimim_quantity', 
    'selected_components.component.cost.order_unit', 
    'selected_components.component.cost.unit_cost',
    'selected_components.component.cost.unit_import_costs',
    'selected_components.component.cost.shipping_costs',
    'selected_components.component.cost.currency.id',
    'selected_components.component.cost.currency.symbol',
    'selected_components.component.cost.currency.convertion_to_euro',
    'selected_components.component.cost.supplier.id',
    'selected_components.component.cost.supplier.name',
    'selected_components.component.cost.order_link',
    'sub_parts.child.id','sub_parts.quantity','sub_parts.id']);
}

function recursiveCreateComponentSelectionMap(production_bom, componentSelectionMap){
  if (production_bom.selected_components && production_bom.selected_components.length > 0) {
    production_bom.selected_components.forEach( (component_selection) => { componentSelectionMap.set(component_selection.id, component_selection) });    
  }
  if (production_bom.sub_parts && production_bom.sub_parts.length > 0){
    production_bom.sub_parts.forEach( (sub_part) => { recursiveCreateComponentSelectionMap(sub_part.child, componentSelectionMap)});
  }
}


// Fills in the componentMap using the name of the producer as the first key, and the id of the component as the second key
function recursiveCreateComponentMap(production_bom, componentMap){
  if (production_bom.selected_components && production_bom.selected_components.length > 0) {
    production_bom.selected_components.forEach( (component_selection) => {
      if (!component_selection.component) { // Ignore, if the component is not specified
        // Add some kind of warnings?
      }
      else{
        let producer_name = "unknown";
        if ( component_selection.component.producer && component_selection.component.producer.name){
          producer_name = component_selection.component.producer.name;
        }  
        if (!componentMap.has(producer_name)){
          componentMap.set(producer_name, new Map());
        }
        let producerComponentMap = componentMap.get(producer_name);
        var current_quantity = 0;
        if (producerComponentMap.has(component_selection.component.id)) {
          current_quantity = producerComponentMap.get(component_selection.component.id).quantity;
        }
        else{
          let newComponent = Object.assign({}, component_selection.component);
          producerComponentMap.set(component_selection.component.id, newComponent);
        }
        producerComponentMap.get(component_selection.component.id).quantity = current_quantity + component_selection.quantity;  
      } 
    });    
  }
  if (production_bom.sub_parts && production_bom.sub_parts.length > 0){
    production_bom.sub_parts.forEach( (sub_part) => { 
      for (let i = 0; i < sub_part.quantity; i++) {
        recursiveCreateComponentMap(sub_part.child, componentMap)
      }
    });
  }
}

// Fills in the componentMap using the name of the producer as the first key, and the id of the component as the second key
function recursiveCreateComponentQuantityMap(production_bom, componentQuantityMap){
  if (production_bom.selected_components && production_bom.selected_components.length > 0) {
    production_bom.selected_components.forEach( (component_selection) => {
      if (!component_selection.component) { // Ignore, if the component is not specified
        // Add some kind of warnings?
      }
      else{
        var quantity = 0;
        if (componentQuantityMap.has(component_selection.component.id)) {
          quantity = componentQuantityMap.get(component_selection.component.id).quantity;
        }
        componentQuantityMap.set(component_selection.component.id, {quantity: quantity+component_selection.quantity, component:component_selection.component});
      }
    });
  }
  if (production_bom.sub_parts && production_bom.sub_parts.length > 0){
    production_bom.sub_parts.forEach( (sub_part) => { 
      for (let i = 0; i < sub_part.quantity; i++) {
        recursiveCreateComponentQuantityMap(sub_part.child, componentQuantityMap);
      }
    });
  }
}



// Fills in the componentMap using the name of the supplier as the first key, and the id of the component as the second key
function createSupplierOrderMap(production_bom, supplierOrderMap, componentCostMap, remainingComponentsList, lot_size){
  let componentQuantityMap = new Map;

  recursiveCreateComponentQuantityMap(production_bom, componentQuantityMap);
  componentQuantityMap.forEach( (quantity_component) => {
    let quantity = quantity_component.quantity;
    let component = quantity_component.component;
    let supplier_name = "unknown";

    // Select the supply that minimixes the total cost
    let max_cost =  100000000000;
    let min_order_cost = max_cost;
    let min_order_cost_quantity = quantity;
    let min_order_cost_formula = null;
    for ( const cost of component.cost) {
      let cost_in_eur = Number(cost.unit_cost * cost.currency.convertion_to_euro);
      let order_quantity = Math.max(quantity*lot_size, cost.minimim_quantity, cost.order_unit) ;
      let order_cost = cost_in_eur * order_quantity;
      if (order_cost < min_order_cost){
        min_order_cost = order_cost;
        min_order_cost_quantity = order_quantity;
        min_order_cost_formula = cost;
      }
    }

    if (min_order_cost_formula){
      let component_unit_cost = (min_order_cost_formula.unit_cost+min_order_cost_formula.unit_import_costs + min_order_cost_formula.shipping_costs)*min_order_cost_formula.currency.convertion_to_euro;
      componentCostMap.set(component.id, component_unit_cost);
      supplier_name = min_order_cost_formula.supplier.name;
      if (!supplierOrderMap.has(supplier_name)){
        supplierOrderMap.set(supplier_name, []);
      }
      let order = {
        component: component,
        cost: min_order_cost,
        cost_formula : min_order_cost_formula, 
        order_quantity: min_order_cost_quantity
      };
      supplierOrderMap.get(supplier_name).push(order);
    } 
    else {
      let order = {
        component: component,
        order_quantity: quantity,
      };
      remainingComponentsList.push(order);     
    }
        
  });

  //console.log(supplierOrderMap);
}





export {recursiveReadBom as default, recursiveSetCosts, recursiveCreateComponentSelectionMap, recursiveCreateComponentMap, createSupplierOrderMap};
