<template>

<v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
    >
      <template v-slot:activator="{ on, attrs }">
        <v-img contain
            v-bind="attrs"
            v-on="on"
            :aspect-ratio="16/9" 
            :height="height" 
            :src="$directus.transport.url + '/assets/' + image_id + '?access_token=' + $directus.auth.token ">  
        </v-img>   
      </template>

      <img contain
            @click="dialog = false"
            style="width=100%; height:100%; object-fit: contain"
            :src="$directus.transport.url + '/assets/' + image_id + '?access_token=' + $directus.auth.token ">  
    </v-dialog>

</template>

<script>
export default {

  data: () => ({
    dialog: false,
  }),
  
  props: {
    height: {
      required: false,
      default: 200,
    },
    image_id: {
      required: true,      
    },
  },

}
</script>

<style>

</style>