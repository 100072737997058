import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import { MemoryStorage, Directus, AxiosTransport, Auth } from '@directus/sdk';

const url = 'http://tools.tactilerobots.com:8055';

// Storage adapter where refresh tokens are stored in JSON mode.
const storage = new MemoryStorage({prefix:"tools"});

// Transport used to communicate with the server.
const transport = new AxiosTransport(url, storage, async () => {
	await auth.refresh(); // This is how axios checks for refresh
});

// Auth is how authentication is handled, stored, and refreshed.
const auth = new Auth(transport, storage, {
mode: 'json',//'cookie', // or json, depends on your use  case
});

Vue.prototype.$directus = new Directus(url, {
auth,
storage,
transport,
});

// Map from the index of a component selection to the object in the tree of the BOM
Vue.prototype.$componentSelectionMap = new Map();

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
