<template>
  <v-app>
    <v-system-bar class="d-print-none"
      app
      color="grey lighten-2"
    >
      <!--div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="./assets/logo.webp"
          transition="scale-transition"
          width="250"
        />
      </div-->

      <router-link :to="{ name: 'BOMS'}" class="text-decoration-none black--text">
        <v-btn
          text
        >
          <span class="mr-2">Bills of material</span>
        </v-btn>
      </router-link>

      <a      
        :href="this.$directus.transport.url + '/admin/collections/component'"
        target="_blank"        
      >
        <span class="mr-2">Components</span>
      </a>

      <a      
        :href="this.$directus.transport.url + '/admin/collections/component_category/21'"
        target="_blank"        
      >
        <span class="mr-2">Categories</span>
      </a>

      <v-spacer></v-spacer>

      <v-btn
        @click="logOut"
        text
      >
        <span class="mr-2">Log out</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-system-bar>

    <v-main>
      <authentication  v-if="!loggedin" @loggedin="onLoggedIn()"></authentication>
      <router-view v-if="loggedin"></router-view>
    </v-main>
  </v-app>
</template>

<script>

import Authentication from './components/Authentication.vue';

export default {
  name: 'App',

  components: {
    Authentication

  },

  data: () => ({
    loggedin: false,
    //
  }),

  async mounted() {
  },

  methods: {
    onLoggedIn: function () {
      console.log("logged-in event received")
      this.loggedin = true;
    },
    logOut: async function() {
      await this.$directus.auth.logout();
      this.loggedin = false;
    }
  }

};
</script>
