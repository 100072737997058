<template >

  <v-card
    class="mx-auto"
    flat
  >

      <v-list-item  class="pl-4" @mouseover="hover=true" @mouseout="hover=false">
          <v-list-item-avatar
            :size="image_width"
            color="grey lighten-2"
          >
            <v-img contain :max-width="image_width" v-if="production_bom_relation.child.picture" :src="$directus.transport.url + '/assets/' + production_bom_relation.child.picture.id + '?access_token=' + $directus.auth.token + '&quality=100&width=' + image_width"></v-img>
          </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="h4 font-weight-bold mb-1">
            <span v-if="production_bom_relation.id != -1" class="font-weight-bold">
              {{ production_bom_relation.quantity }}
            </span>
            <span v-if="production_bom_relation.id != -1">
              x
            </span>
            <span>
              {{ production_bom_relation.child.internal_code }}
            </span>  

            <span v-if="production_bom_relation.child.total_cost>0">
               @ <span class="text-decoration-underline">{{formatted_unit_cost}}€</span>  
              <span v-if="production_bom_relation.quantity>1 && production_bom_relation.id >= 0" > = {{formatted_total_cost}} €</span> 
            </span>

            <div class="toolset"  v-show="hover">

              <v-btn icon color="gray" @click="show_details=!show_details">
                <v-icon v-show="show_details">mdi-chevron-up</v-icon>
                <v-icon v-show="!show_details">mdi-chevron-down</v-icon>
              </v-btn>

              <v-btn icon color="gray" @click="$emit('open_all')">
                <v-icon>mdi-chevron-double-down</v-icon>
              </v-btn>

              <v-btn icon color="gray" @click="$emit('close_all')">
                <v-icon>mdi-chevron-double-up</v-icon>
              </v-btn>

              <router-link :to="{ name: 'BOM', params: { bom_id: this.$route.params.bom_id, collection:'bom', item_id: production_bom_relation.child.id }}" class="text-decoration-none black--text">
                <v-btn icon color="gray">   
                  <v-icon>mdi-arrow-right-bold</v-icon>
                </v-btn>             
              </router-link>

              <v-btn icon color="gray" class="handle" style="cursor:grabbing">
                <v-icon>mdi-drag</v-icon>
              </v-btn>

              <v-btn v-if="production_bom_relation.id>0" icon color="gray" @click="decrementQuantity()">
                <v-icon>mdi-minus</v-icon>
              </v-btn>

              <v-btn v-if="production_bom_relation.id>0" icon color="gray" @click="incrementQuantity()">
                <v-icon>mdi-plus</v-icon>
              </v-btn>

              <router-link :to="{ name: 'BOM', params: { bom_id: production_bom_relation.child.id }}" class="text-decoration-none black--text">
                <v-btn v-if="production_bom_relation.id>0" icon color="gray">
                  <v-icon>mdi-magnify-plus</v-icon>
                </v-btn>
              </router-link>

              <router-link :to="{ name: 'BOM', params: { bom_id: production_bom_relation.child.id, collection: 'component' }}" class="text-decoration-none black--text">
                <v-btn v-if="production_bom_relation.id==-1" icon color="gray">
                  <v-icon>mdi-format-list-bulleted</v-icon>
                </v-btn>
              </router-link>
              
              <router-link :to="{ name: 'BOM', params: { bom_id: production_bom_relation.child.id, collection: 'orderBOM' }}" class="text-decoration-none black--text">
                <v-btn v-if="production_bom_relation.id==-1" icon color="gray">
                  <v-icon>mdi-cart</v-icon>
                </v-btn>
              </router-link>

              <!--a :href="this.$directus.transport.url + '/admin/collections/production_bom/' + production_bom_relation.child.id"
                target="_blank"        
              >
                <v-avatar
                  size="20"
                >
                  <img
                    
                    src="../assets/directus.jpeg"
                    alt="Directus"
                  >
                </v-avatar>
              </a-->
            </div>

          </v-list-item-title>

        </v-list-item-content>
        </v-list-item>

    <div v-show="show_details">
      <slot>
      </slot>
    </div>  

  </v-card>

</template>

<script>
export default {

  name: "bom-line",
  
  data : () => {
    return {
      show_details: false,
      hover: false,
    }
  },

  computed: {
    formatted_unit_cost: function () {
      return this.production_bom_relation.child.total_cost.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
    },
    formatted_total_cost: function () {
      return this.production_bom_relation.total_cost.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
    },
  },

  props: {
    production_bom_relation: {
      type: Object,
      required: true,
    },
    image_width: {
      type: Number,
      required: false,
      default: 50,
    },
    open_all: {
      type: Boolean,
      required: true,
    },
    close_all: {
      type: Boolean,
      required: true,
    },

  },

  watch : {
    'open_all': function(){
      this.show_details = true;
    },
    'close_all': function(){
      this.show_details = false;
    },
    'production_bom_relation.quantity': function(val){
      if (val == 0) {
        // remove the sub BOM from the list of sub boms
        this.$emit("subBomRemoved");
      }
      else {
        // update the quantity
        this.updateQuanty();
      }
    }
  },

  methods: {
    async updateQuanty(){
      if (this.production_bom_relation.id>=0) {
        // Update the database
        const production_bom_relations = this.$directus.items('production_bom_relation');    
        await production_bom_relations.updateMany([{
            id: this.production_bom_relation.id,
            quantity:this.production_bom_relation.quantity,
          }],
          { 
            fields: ['quantity'] 
          }
        );
      }
    },
    incrementQuantity(){
      this.production_bom_relation.quantity++;
    },
    decrementQuantity(){
      if (this.production_bom_relation.quantity < 1) {
        return;
      }
      if (this.production_bom_relation.quantity < 2) {
        if (!window.confirm("Remove the BOM with ID " + this.production_bom_relation.child.id + " and name " + this.production_bom_relation.child.internal_code + " from this BOM?")){        
          return; 
        }
      }
      this.production_bom_relation.quantity--;
    },
  },


}
</script>

<style scoped>
div.toolset{
  position: absolute;
  left:70px;
  top:50px;
  background-color: white;
}
div.v-list-item{
  padding-bottom: 25px;
}
</style>