<template>
  <v-card v-if="production_bom_relation.child" class="mx-auto" flat>
    <bom-line
      :production_bom_relation="production_bom_relation"
      :open_all="sub_open_all"
      :close_all="sub_close_all"
      :image_width="50" 
      @subBomRemoved="$emit('subBomRemoved')"
      @open_all="on_open_all()"
      @close_all="on_close_all()"
      >

      <components :production_bom="production_bom_relation.child" :open_all="sub_open_all" :close_all="sub_close_all">
      </components>  

      <sub-parts :production_bom="production_bom_relation.child" :open_all="sub_open_all" :close_all="sub_close_all">
      </sub-parts> 

    </bom-line>    


  </v-card>
</template>

<script>

import BomLine from './BomLine.vue';
import SubParts from './SubParts.vue';
import Components from './Components.vue';

export default {
  name: "BomTree",

  components: {
    BomLine,
    Components,
    SubParts,
  },

  data: () => { 
    return {
      show_details: false,
      sub_open_all: false,
      sub_close_all: false,
    }
  },


  props: {
    production_bom_relation: {
      type: Object,
      required: true,
    },    
    open_all: {
      type: Boolean,
      required: true,
    },
    close_all: {
      type: Boolean,
      required: true,
    },
  },

  watch : {
    'open_all': function(){
      this.on_open_all();
    },    
    'close_all': function(){
      this.on_close_all();
    },
  },

  async mounted() {
    //console.log(this.production_bom_relation);
  },

  computed: {
  
  },

  methods: {  
    on_open_all(){
      this.show_details = true;
      this.sub_open_all = !this.sub_open_all;
    }, 
    on_close_all(){
      this.show_details = false;
      this.sub_close_all = !this.sub_close_all;
    },

  }

};
</script>

<style>
.v-list-item {
  paddin0g-left:10px;
  margin:0px;
}
</style>