<template>

    <v-list-item @mouseover="hover=true" @mouseout="hover=false">

        <v-list-item-avatar
            rounded
            :size="image_width"
            color="transparent"
            class="d-flex"
        >
            <v-img contain :max-width="image_width" v-if="component_selection.component && component_selection.component.main_picture" :src="$directus.transport.url + '/assets/' + component_selection.component.main_picture.id + '?access_token=' + $directus.auth.token + '&quality=100&width=' + image_width"></v-img>
        </v-list-item-avatar>


        <v-list-item-content >
          
          <v-list-item-title >

            <span>
              {{component_selection.quantity}} x
            </span> 
            <strong><span  v-if="component_selection.component">
              {{component_selection.component.internal_code}}
            </span></strong>

            <span v-if="component_selection.total_cost">
               @ <span class="text-decoration-underline">{{formatted_unit_cost}}€</span>  
              <span  v-if="component_selection.quantity>1" > = {{formatted_total_cost}} €</span> 
            </span>

            <div class="toolset" v-show="hover">

              <router-link :to="{ name: 'BOM', params: { bom_id: this.$route.params.bom_id, collection:'component_selection', item_id: component_selection.id }}" class="text-decoration-none black--text">
                <v-btn icon color="gray">   
                  <v-icon>mdi-arrow-right-bold</v-icon>
                </v-btn>
              </router-link>
              <span v-if="!read_only">
                <v-btn icon color="gray" class="handle" style="cursor:grabbing">
                  <v-icon>mdi-drag</v-icon>
                </v-btn>

                <v-btn icon color="gray" @click="$emit('componentSelectionCloned')">
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>

                <v-btn icon color="gray" @click="decrementQuantity()">
                  <v-icon>mdi-minus</v-icon>
                </v-btn>

                <v-btn icon color="gray" @click="incrementQuantity()">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </span>              
              <!--a :href="this.$directus.transport.url + '/admin/collections/component_selection/' + component_selection.id"
                target="_blank"        
              >
                <v-avatar
                  size="20"
                >
                  <img
                    
                    src="../assets/directus.jpeg"
                    alt="Directus"
                  >
                </v-avatar>
              </a-->
            </div>
          </v-list-item-title >
          <v-list-item-subtitle>
            <span  v-if="component_selection.component && component_selection.component.category">
              {{component_selection.component.category.name}} 
            </span> 
            <span  v-if="component_selection.short_description">
              : {{component_selection.short_description}}
            </span>
          </v-list-item-subtitle>
        </v-list-item-content>  
    </v-list-item>
    
</template>

<script>
export default {

  name: "component-line",

  props: {
    component_selection: {
      type: Object,
      required: true,
    },
    image_width: {
      type: Number,
      required: false,
      default: 50,
    },
    read_only: {
      type: Boolean,
      required: false,
      default: false,
    }  
  },

  data : () => {
    return {
      hover: false,
    }
  },

  computed: {
    formatted_unit_cost: function () {
      return this.component_selection.unit_cost.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
    },
    formatted_total_cost: function () {
      return this.component_selection.total_cost.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
    },
  },

  watch : {
    'component_selection.quantity': function(val){
      if (val == 0) {
        // remove the sub BOM from the list of sub boms
        this.$emit("componentSelectionRemoved");
      }
      else {
        // update the quantity
        this.updateQuanty();
      }
    }
  },

  methods: {
    async updateQuanty(){
      // Update the database
      const component_selections = this.$directus.items('component_selection');    
      await component_selections.updateMany([{
          id: this.component_selection.id,
          quantity:this.component_selection.quantity,
        }],
        { 
          fields: ['quantity'] 
        }
      );
    },
    incrementQuantity(){
      this.component_selection.quantity++;
    },
    decrementQuantity(){
      if (this.component_selection.quantity < 1) {
        return;
      }
      if (this.component_selection.quantity < 2) {
        if (!window.confirm("Remove the component selection with ID " + this.component_selection.id + " from this BOM?")){        
          return; 
        }
      }
      this.component_selection.quantity--;
    },
    
  },


}
</script>

<style scoped>
div.v-list-item.sortable-chosen:hover{
    background-color: lightyellow;
}
div.toolset{
  position: absolute;
  left:70px;
  top:50px;
  background-color: white;
}
div.v-list-item{
  padding-bottom: 25px;
}
</style>