<template>
  <v-hover v-slot="{ hover }">
    <v-card outlined
      @click="$router.push({ name: 'BOM', params: { bom_id: production_bom.id, collection: 'none', item_id:'-1' }})"
      class="mx-auto transition-swing rounded-lg"
      :class="`elevation-${hover ? 24 : 6}`"
      flat
    >
      <v-list-item >
        <v-list-item-avatar
          rounded
          :size="image_width"
          color="grey"
        >
          <v-img :max-width="image_width" v-if="production_bom.picture" :src="$directus.transport.url + '/assets/' + production_bom.picture.id + '?access_token=' + $directus.auth.token + '&quality=100&width=' + image_width"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="headline mb-1 text-decoration-none">
            {{ production_bom.internal_code }}
          </v-list-item-title>
        </v-list-item-content>
        
      </v-list-item>

      <!--v-card-text v-show="hover">
        {{ production_bom.description }}
      </v-card-text-->
    </v-card>
  </v-hover>
   

</template>

<script>
export default {

  name: "BomCard",

  props: {
    production_bom: {
      type: Object,
      required: true,
    },
    image_width: {
      type: Number,
      required: false,
      default: 50,
    }
  },

}
</script>

<style>

</style>