<template>
    <v-card v-if="ready">

      <v-toolbar dark :color="line_color">
  

           <v-text-field 
                  class="text-h4"
                  style="padding-top:25px; width:100%"
                  @input="unchanged=false"
                  v-model="bom.internal_code"
          ></v-text-field>


        <v-spacer></v-spacer>

        <a class="d-print-none" :href="this.$directus.transport.url + '/admin/collections/production_bom/' + bom.id" target="_blank">
          <v-btn light>
            Details
          </v-btn>
        </a>

        <v-btn class="d-print-none" light
          @click="save_and_update"
          :disabled="unchanged">
          Save
        </v-btn>

      </v-toolbar>
  
        <v-card
          class="mx-auto"
          v-if="bom"
        >

          <v-row>
            <v-col>
                <v-list-item>
                  <v-list-item-content>

                    <v-list-item-title :class="{ 'd-print-none' : !bom.description }">
                      <v-text-field 
                        class="text-h6"
                        @input="unchanged=false"
                        v-model="bom.description"
                      ></v-text-field>
                    </v-list-item-title>

                    <v-list-item-subtitle v-if="bom.external_code">
                      external_code: {{bom.external_code}}
                    </v-list-item-subtitle>

                    <div :class="{ 'd-print-none' : !bom.long_description}">
                        Details
                      <WYSIWYG @input="unchanged=false" v-model="bom.long_description" style="margin-top:0px; background-color:whitesmoke">                  
                      </WYSIWYG>
                    </div>

                  </v-list-item-content>
                                
                </v-list-item>
                  
            </v-col>
            <v-col>
              <exandable-image 
                v-if="bom && bom.picture" 
                :image_id="bom.picture.id"
                height="400px"
              >
              </exandable-image>
            </v-col>
          </v-row>        

          <v-container fluid>
            <v-row>
              <v-col v-if="componentsToShow.length > 0">
                Components
                  <component-line v-for="component_selection in componentsToShow"
                    :key="component_selection.id"
                    :component_selection="component_selection"
                    :image_width="50"
                    :read_only=true
                  >         
                  </component-line>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="bom.sub_parts.length > 0">
                Sub parts
                  <v-list-item v-for="sub_part in bom.sub_parts" :key="sub_part.id">
                    <v-list-item-avatar size="50px">
                      <exandable-image 
                        v-if="sub_part.child.picture" 
                        :image_id="sub_part.child.picture"
                        height="250px"
                      >
                      </exandable-image>
                    </v-list-item-avatar>
                    {{sub_part.quantity}} x {{sub_part.child.internal_code}}
                  </v-list-item>
              </v-col>
            </v-row>
            <v-row :class="{ 'd-print-none' : bom.tools.length == 0}">
              <v-col>
                Tools
                <v-dialog
                  v-model="add_tool_dialog"
                  persistent
                  max-width="600px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="d-print-none" 
                      icon color="gray"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-plus</v-icon>                 
                   </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">Add tool</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col>
                            <v-autocomplete
                              v-model="new_tool_category"
                              :items="tool_categories"
                              label="Category"
                              class="text-h5"
                              @click="updateCategories"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-text-field
                              v-model="new_tool_notes"
                              label="Notes"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="add_tool_dialog = false"
                      >
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="add_tool_dialog = false;addToolAndUpdate()"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>              

                <v-list two-line>
                  <v-list-item v-for="tool in bom.tools" :key="tool.id">      
        <v-list-item-avatar
            rounded
            :size="50"
            color="transparent"
        >
                    <v-img contain :max-width="50" v-if="tool.category.picture" :src="$directus.transport.url + '/assets/' + tool.category.picture.id + '?access_token=' + $directus.auth.token + '&quality=100&width=' + 50"></v-img>
        </v-list-item-avatar>

                    <v-list-item-title v-if="tool.category && tool.category.name" >
                      {{tool.category.name}}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="tool.notes">
                      {{tool.notes}}
                    </v-list-item-subtitle>
                    <v-btn
                      class="d-print-none" 
                      icon color="gray"
                      @click="removeToolAndUpdate(tool.id)"
                    >
                      <v-icon>mdi-minus</v-icon>                 
                   </v-btn>
                  </v-list-item>       
                </v-list>
              </v-col>
            </v-row>
            <v-row>
              <v-col :class="{ 'd-print-none' : !bom.assembly_instructions}">
                Assembly instructions
                <WYSIWYG @input="unchanged=false" v-model="bom.assembly_instructions" style="margin-top:0px; background-color:whitesmoke">                  
                </WYSIWYG>  
              </v-col>
            </v-row>
            <v-row class="d-print-none">
              <v-col>
                Link to external assembly instructions


                <a v-if="bom.external_assembly_instructions_link" :href="bom.external_assembly_instructions_link"  target="_blank">
                  <v-btn dark>
                    Go to link
                  </v-btn>
                </a>

                <v-text-field 
                  @input="unchanged=false"
                  v-model="bom.external_assembly_instructions_link"
                ></v-text-field>  

              </v-col>
            </v-row>
          </v-container>
        </v-card>

    </v-card>

    
</template>

<script>
import ComponentLine from './ComponentLine.vue';
import ExandableImage from './ExandableImage.vue';
import WYSIWYG from './WYSIWYG.vue';

export default {
  components: { WYSIWYG, ExandableImage, ComponentLine },

  data : () => {
    return {
      bom: {},
      ready: false,
      component_quantities: new Map(),
      unchanged: true,
      add_tool_dialog: false,
      new_tool_category: "",
      new_tool_notes: "",
      tool_categories: [],
    }
  },

  props: {
    item_id: {
      required: false,
      default: null,
    },
    line_color: {
      type: String,
      required: false,
      default: "blue",
    },

  },

  async mounted() {    
    await this.update();    
    this.ready = true;
  },

  methods: {
    async update () {
      // Update the bom
      const boms = this.$directus.items('production_bom');      
      this.bom = await boms.readOne(this.item_id, { 
        fields: ["id", "description",
                 "external_code", "internal_code", "long_description",
                 "tools", "assembly_instructions","external_assembly_instructions_link",
                 "picture.id",
                 'selected_components.id','selected_components.quantity','selected_components.short_description', 
                 'selected_components.component.id','selected_components.component.title',
                 'selected_components.component.internal_code','selected_components.component.category.name',
                 'selected_components.component.main_picture.id','selected_components.component.producer.name',
                 'tools.id','tools.notes', 'tools.category.name', 'tools.category.picture.id',
                 'sub_parts.child.id','sub_parts.quantity',
                 "sub_parts.child.picture","sub_parts.child.internal_code","sub_parts.child.description"
                 ] });
      console.log(this.bom);
    },
    async addToolAndUpdate () {
      const tools = this.$directus.items('tool');
      await tools.createOne({
        notes: this.new_tool_notes,
        category: this.new_tool_category,
        production_bom: this.item_id,
      });
      await this.update();
    }, 
    async removeToolAndUpdate (id) {
      const tools = this.$directus.items('tool');
      await tools.deleteOne(id);
      await this.update();
    }, 
    async save () {
      const production_bom = this.$directus.items('production_bom');    
      await production_bom.updateMany([{
          id: this.bom.id,
          internal_code:this.bom.internal_code,
          description: this.bom.description,
          long_description:this.bom.long_description,
          assembly_instructions:this.bom.assembly_instructions, 
          external_assembly_instructions_link: this.bom.external_assembly_instructions_link,                   
        }],
        { 
          fields: ['short_dlong_descriptionescription',"assembly_instructions"]
        }
      );
      // Disable the save button
      this.unchanged = true;
      this.$emit("component-selection-modified",this.component_selection);
    },
    async save_and_update(){
      await this.save();
      await this.update();
    },
    async updateCategories() {
      // Update the list of the categories
      const categories = this.$directus.items('component_category');
      let result = await categories.readMany({fields: ["id","name","parent.id","sub_categories"], limit:-1 }); 

      this.categories = result.data;
      let tool_categories = this.getSubCategories("190");
      this.tool_categories = Array.from(tool_categories, item => {return {text:item.name, value:item.id}});     
    },
    getSubCategories (category_id){
      var result = [];
      let parent_category = this.categories.find(category => category.id == category_id);
      if (parent_category) {
        result = [parent_category]
        var i;
        for (i = 0; i < parent_category.sub_categories.length; i++) {
          result = result.concat(this.getSubCategories(parent_category.sub_categories[i]));
        }        
      }
      return result;
    },
  },

  computed: {
     numberOfComponents () {
      let count = 0;
      if (!this.bom.selected_components){
        return 0;
      }
      this.bom.selected_components.forEach(element => {
        count+=element.quantity;
      });
      return count;
    },
    componentsToShow(){
      return this.bom.selected_components;
    },

  },

  watch : {
    item_id: async function(){
      await this.update();
    },
  },

}
</script>

<style>
  .v-card__title input:focus {
      outline: none;
  }

</style>
