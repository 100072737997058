<template>
    <v-dialog
      v-model="dialog"
      hide-overlay
      max-width="60%"
      origin="right right"
      transition="slide-x-reverse-transition"
      content-class="right-dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="add" icon :color="color" 
            v-bind="attrs"
            v-on="on"
            :disabled="disabled">
            <v-icon>mdi-playlist-plus</v-icon>
        </v-btn>

        <v-btn v-else light  
            v-bind="attrs"
            v-on="on"
            :disabled="disabled">
          <span>  
            Select
          </span>

        </v-btn>

      </template>

      <v-card>
        <v-toolbar
          dark
          :color="color"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
              <slot name="title"></slot>
         </v-toolbar-title>
        </v-toolbar>
        <v-list
          three-line
          subheader
        >
          <v-card>
            <v-card-title>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
            >

              <template v-slot:[item_dot_picture]="{ item }">
                
                <v-list-item-avatar
                    rounded
                    :size="image_width"
                    color="transparent"
                >
                    <v-img :max-width="image_width" :src="$directus.transport.url + '/assets/' + item[picture_name] + '?access_token=' + $directus.auth.token + '&quality=100&width=' + image_width"></v-img>
                </v-list-item-avatar>
               
              </template>

              <template v-if="add" v-slot:item.add="{ item }">
                <v-btn icon :color="color" 
                    v-bind="attrs"
                    @click="addItem(item[id_name])">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>

              <template v-if="select" v-slot:item.select="{ item }">
                <v-btn :color="color" 
                    dark
                    v-bind="attrs"
                    @click="selectItem(item[id_name])">
                    choose
                </v-btn>
              </template>


            </v-data-table>
          </v-card>

        </v-list>
      </v-card>
    </v-dialog>
</template>



<script>
export default {
  name: "TabularSelector",

  data () {
    return {
        dialog: false,
        notifications: false,
        sound: true,
        widgets: false,
        search: '',
        headers:[],
    }
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: "primary",
    },
    id_name:  {
      type: String,
      required: false,
      default: "id",
    },
    picture_name:  {
      type: String,
      required: false,
      default: "picture",
    },
    fields:  {
      type: Array,
      required: false,
      default: () => { return [] },
    },
    add: {
      type: Boolean,
      required: false,
      default: false,
    },
    select: {
      type: Boolean,
      required: false,
      default: false,
    },
    image_width:  {
      type: Number,
      required: false,
      default: 40,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

 computed: {
    item_dot_picture () {
      return "item." + this.picture_name;
    },
  },  

  mounted() {
    this.fields.forEach( async (field) =>  {  
      this.headers.push({
        text: field,
        align: 'start',
        filterable: true,
        value: field,
      });
    });
    if (this.select){
      this.headers.push({
        text: "select",
        align: 'start',
        filterable: false,
        value: "select",
      });
    }
    if (this.add){
      this.headers.push({
        text: "add",
        align: 'start',
        filterable: false,
        value: "add",
      });
    }
  },

  methods: {  
    addItem (item_id) {
      this.$emit("itemAdded", item_id);
    },
    selectItem (item_id) {
      this.$emit("itemSelected", item_id);
      this.dialog=false;
    }
  },

  watch : {
    dialog: function(){
      if (this.dialog){
        this.$emit("selectionOpened");
      }
    }
  },


}

</script>

<style scoped>
  >>>.right-dialog {
    align-self: flex-end;
    top: 0;
    right: 0;
    position: absolute;
  }
</style>