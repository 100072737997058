<template>
  <v-card v-if="ready">
    <h1>Order BOM</h1>

    <v-list v-for="[supplier_name, orders] in supplierOrderMap" :key="supplier_name" two-line>

      <h2>{{supplier_name}}</h2>

      <v-list-item v-for="order in orders" :key="order.component.id">

         <v-list-item-avatar
            rounded
            :size="image_width"
            color="transparent"
        >
            <v-img :max-width="image_width" v-if="order.component && order.component.main_picture" :src="$directus.transport.url + '/assets/' + order.component.main_picture.id + '?access_token=' + $directus.auth.token + '&quality=100&width=' + image_width"></v-img>
        </v-list-item-avatar>

        <v-list-item-content >
          
          <v-list-item-title >

            <span>
              {{order.order_quantity}} x
            </span>  

            <span>
              {{order.  component.category.name}} - 
              <strong>{{order.component.internal_code}}</strong>
            </span>

            <strong> 
              @ {{order.cost_formula.unit_cost}} 
              <span v-if="order.cost_formula.unit_import_costs>0"> + {{order.cost_formula.unit_import_costs}}</span> 
              <span v-if="order.cost_formula.shipping_costs>0"> + {{order.cost_formula.shipping_costs}}</span> 
              <span v-if="order.cost_formula.currency">{{order.cost_formula.currency.symbol}}</span> 
            </strong>
          
            <a
              :href="$directus.transport.url + '/admin/collections/component/' + order.component.id"
              target="_blank"    
              class="text-decoration-none black--text"         
            >
            <v-btn text outlined small>Details</v-btn>
            </a>           

            <a
              v-if="order.component.product_page"
              :href="order.component.product_page"
              target="_blank"    
              class="text-decoration-none black--text"         
            >
              <v-btn 
                text 
                small
                outlined>
                Product page
              </v-btn>
            </a>                       

          </v-list-item-title >

          <v-list-item-subtitle>
            {{order.component.title}}
          </v-list-item-subtitle>

        </v-list-item-content>

          <div class="cost_button">
            <strong> {{formatted_cost(order.cost)}} <span v-if="order.cost_formula.currency">{{order.cost_formula.currency.symbol}}</span> </strong>
          </div>


      </v-list-item>
    </v-list>

    <v-list>

        <h2>Remaining components</h2>

        <v-list-item v-for="order in remainingComponentsList" :key="order.component.id">

            <v-list-item-avatar
              rounded
              :size="image_width"
              color="transparent"
          >
              <v-img :max-width="image_width" v-if="order.component && order.component.main_picture" :src="$directus.transport.url + '/assets/' + order.component.main_picture.id + '?access_token=' + $directus.auth.token + '&quality=100&width=' + image_width"></v-img>
          </v-list-item-avatar>

          <v-list-item-content >
            
            <v-list-item-title >

              <span>
                {{order.order_quantity}} x
              </span>  

              <span>
                {{order.component.category.name}} - 
                <strong>{{order.component.internal_code}}</strong>
              </span>

              <a
                :href="$directus.transport.url + '/admin/collections/component/' + order.component.id"
                target="_blank"    
                class="text-decoration-none black--text"         
              >
              <v-btn text outlined small>Details</v-btn>
              </a>           

              <a
                v-if="order.component.product_page"
                :href="order.component.product_page"
                target="_blank"    
                class="text-decoration-none black--text"         
              >
                <v-btn 
                  text 
                  small
                  outlined>
                  Product page
                </v-btn>
              </a>                       

            </v-list-item-title >

            <v-list-item-subtitle>
              {{order.component.title}}
            </v-list-item-subtitle>

          </v-list-item-content>
        </v-list-item>
      
    </v-list>

  </v-card>
</template>


<script>

import { createSupplierOrderMap } from '../scripts/recursiveReadBom';


export default {

  props: {
    production_bom: {
      required: true,
    },
    image_width: {
      type: Number,
      required: false,
      default: 50,
    },
    lot_size: {
      default:1,
    }
  },

  data : () => {
    return {
      supplierOrderMap: null, 
      remainingComponentsList: [],
      ready: false,
    }
  },

  async mounted() {    
    this.update();
    this.ready = true;
  },

  watch : {
    production_bom: async function(){
      this.update();
    },
  },

  methods: {
    update () {
        this.supplierOrderMap = new Map();
        this.supplierCostMap = new Map();
        this.remainingComponentsList = []; 
        createSupplierOrderMap(this.production_bom, this.supplierOrderMap, this.supplierOrderMap, this.remainingComponentsList, this.lot_size);
    },
    formatted_cost ( cost ) {
      return cost.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
    },
  },

}
</script>

<style>
.cost_button {
  border:1px black solid;
  margin: 5px;
  border-radius: 5px;
  padding: 5px
}
</style>