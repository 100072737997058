<template>
  <v-card>
        <v-navigation-drawer  v-if="renderNavigation" class="d-print-none" app permanent width="40%"
 height="100vh">
            <v-row>
             <v-col>
              <v-text-field
                v-model="lot_size"
                label="Lot size"
                type="number"
                min="1"
              ></v-text-field>
             </v-col>
             <v-col cols="auto">
              <v-btn 
                primary
                @click="updateCosts"
                style="margin:10px"
                >
                UPDATE COSTS
              </v-btn>        
             </v-col>
            </v-row>
            <bom-tree v-if="ready && production_bom_relation" :production_bom_relation="production_bom_relation" :open_all="false" :close_all="false"></bom-tree>
        </v-navigation-drawer>
        <div style="height:100vh" >
          <component-selection-editor 
            v-if="collection=='component_selection'" 
            :item_id="item_id"
            @component-selection-modified = "updateComponentSelection"
            style="height:100vh" >
          </component-selection-editor>
          <BOMEditor
            v-if="collection=='bom'" 
            :item_id="item_id"
            style="height:100vh" >
          </BOMEditor>
          <BOMComponentList
            v-if="collection=='component' && production_bom && ready"
            :production_bom="production_bom"
            style="height:100vh" >
          </BOMComponentList>
          <OrderBOM          
            v-if="collection=='orderBOM' && production_bom && ready && renderNavigation"
            :production_bom="production_bom"
            :lot_size="lot_size"
            style="height:100vh" >
          </OrderBOM>
          <bom-tree v-if="collection=='tree' && ready && production_bom_relation" :production_bom_relation="production_bom_relation" :open_all="false" :close_all="false"></bom-tree>
          
        </div>

        <v-dialog
          v-model="loading"
          hide-overlay
          persistent
          width="300"
        >
          <v-card
            color="primary"
            dark
          >
            <v-card-text>
              Loading...
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>

  </v-card>        

</template>

<script>
import BOMComponentList from '../components/BOMComponentList.vue';
import BOMEditor from '../components/BOMEditor.vue';
// @ is an alias to /src
import BomTree from '../components/BomTree.vue';
import ComponentSelectionEditor from '../components/ComponentSelectionEditor.vue';
import OrderBOM from '../components/OrderBOM.vue';
import { createSupplierOrderMap, recursiveSetCosts } from '../scripts/recursiveReadBom';
import recursiveReadBom, { recursiveCreateComponentSelectionMap}  from '../scripts/recursiveReadBom';

export default {
  name: 'BOM',

  components: {
    BomTree,
    ComponentSelectionEditor,
    BOMEditor,
    BOMComponentList,
    OrderBOM,
  },

  props: {
    bom_id: {
      required: true,
    },
    collection: {
      type: String,
      required: false,
      default: null,
    },
    item_id: {
      required: false,
      default: null,
    },
  },

  data : () => {
    return {
      production_bom: null,
      production_bom_relation: null,
      ready: false,
      component_selection: null,
      renderNavigation: true,
      lot_size: 1,
      supplierOrderMap: null,
      componentCostMap: null,
    }
  },

  watch : {
    production_bom: function() {
      this.updateCosts();
    },
    bom_id: async function(){
      this.ready = false;
      await this.update();
      this.ready = true;
    },
  },

  computed: {
    loading () {
      return ! this.ready;
    },
  },

  async mounted() {    
    await this.update();
    this.ready = true;
    window.addEventListener('resize', this.forceReRender);
    window.addEventListener('beforeprint', ()=>{this.renderNavigation=false});

  },

  methods: {
    forceReRender(){
      // Remove my-component from the DOM
      this.renderNavigation = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderNavigation = true;
      });
    },

    updateComponentSelection(modified_component_selection){
      if (this.$componentSelectionMap.has(modified_component_selection.id)){
        let component_selection_in_bom_tree = this.$componentSelectionMap.get(modified_component_selection.id);
        component_selection_in_bom_tree.quantity = modified_component_selection.quantity;
        component_selection_in_bom_tree.short_description = modified_component_selection.short_description;
        component_selection_in_bom_tree.component = modified_component_selection.component;
      }
    },

    async update () {
      const production_boms = this.$directus.items('production_bom');
      this.supplierOrderMap = new Map();
      this.componentCostMap = new Map();
      this.remainingComponentsList = []; 
      this.production_bom = await recursiveReadBom(production_boms,this.bom_id, this.$componentSelectionMap);
      createSupplierOrderMap(this.production_bom, this.supplierOrderMap, this.componentCostMap, this.remainingComponentsList, this.lot_size);
      console.log(this.supplierCostMap);
      recursiveSetCosts(this.production_bom, this.componentCostMap );
      this.$componentSelectionMap = new Map();
      recursiveCreateComponentSelectionMap(this.production_bom, this.$componentSelectionMap);
      this.production_bom_relation = {
        id: -1,
        parent: -1,
        child: this.production_bom,
        quantity: 1
      }
    },

    updateCosts(){
      createSupplierOrderMap(this.production_bom, this.supplierOrderMap, this.componentCostMap, this.remainingComponentsList, this.lot_size);
      recursiveSetCosts(this.production_bom, this.componentCostMap);
      //this.$componentSelectionMap = new Map();
      //recursiveCreateComponentSelectionMap(this.production_bom, this.$componentSelectionMap);
      this.forceReRender();
    },
  },

}
</script>
