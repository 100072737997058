<template>
  
    <v-list>

        <v-list-item style="margin-left:13px">          
            <v-list-item-content>
            <v-list-item-title class="font-weight-bold">
                <!--v-icon  :color="line_color">
                mdi-circle
                </v-icon-->                 
                {{numberOfSubParts}} Sub parts

                <v-btn icon
                    :color="line_color"
                    @click="show_details=!show_details"
                    v-if="numberOfSubParts>0">
                    <v-icon v-show="show_details">mdi-chevron-up</v-icon>
                    <v-icon v-show="!show_details">mdi-chevron-down</v-icon>
                </v-btn>


                  <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="600px"
                  >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon
                      v-bind="attrs"
                      v-on="on"
                      :color="line_color"
                    >
                      <v-icon>mdi-plus</v-icon>
                  </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">Add new sub BOM</span>
                    </v-card-title>
                    <v-card-body>
                      <v-text-field 
                        v-model="name_of_new_bom"
                        label="Name of the new BOM"
                      ></v-text-field>
                    </v-card-body>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialog = false"
                      >
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialog = false; addSubBom(name_of_new_bom)"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <tabular-selector
                     v-if="childbleBoms" 
                     :color="line_color"                      
                     :items="childbleBoms"
                     picture_name="picture"
                     :fields="['picture','internal_code','description']"
                     :add="true"
                     @itemAdded="addItem"
                     @selectionOpened="updateChildbleBoms"
                     >
                    <template v-slot:title >
                        Select the new sub bom
                    </template>   
                </tabular-selector>    


            </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    
        <draggable 
            v-model="production_bom.sub_parts"
            group='sub_parts'
            :sort="false"
            :move="onMoveProductionBom"
            class="ml-8" 
            style="min-height: 20px;"
            :style="{color:line_color}"
            :id="production_bom.id"
            handle=".handle"
            delay="50"
        >

                <v-list-item
                    v-for="sub_part in subPartsToShow"
                    :key="sub_part.child.id"
                    style="border-left: 2px solid"
                    :style="{borderLeftColor:line_color}"
                    v-show="show_details"
                >
                    <BomTree
                        :production_bom_relation="sub_part" 
                        :open_all="sub_open_all"
                        :close_all="sub_close_all"
                       @subBomRemoved="removeSubBomRelation(sub_part)"                       
                        class="ml-0"
                    >
                    </BomTree>
                </v-list-item>


        </draggable>

    </v-list>

</template>

<script>

import draggable from "vuedraggable";
import TabularSelector from './TabularSelector.vue';
import recursiveReadBom from '../scripts/recursiveReadBom';

export default {

  name: "sub-parts",

  components: {
    BomTree: () => import('./BomTree.vue'),
    draggable,
    TabularSelector,
  },

  data : () => {
    return {
      show_details: false,
      childbleBoms: null,
      dialog: false,
      name_of_new_bom : "",
      sub_open_all: false,
      sub_close_all: false,
    }
  },

  props: {
    production_bom: {
      type: Object,
      required: true,
    },
    line_color: {
      type: String,
      required: false,
      default: "brown",
    },
    open_all: {
      type: Boolean,
      required: true,
    },
    close_all: {
      type: Boolean,
      required: true,
    },

  },

  watch : {
    'open_all': function(){
      this.show_details = true;
      this.sub_open_all= !this.sub_open_all;
    },
    'close_all': function(){
      this.show_details = false;
      this.sub_close_all= !this.sub_close_all;
    }
  },

  computed: {
    numberOfSubParts () {
      let count = 0;
      if (!this.production_bom.sub_parts){
        return 0;
      }
      this.production_bom.sub_parts.forEach(element => {
        count+=element.quantity;
      });
      return count;
    },
    subPartsToShow(){
      return this.production_bom.sub_parts;
      /*  if (this.show_details){
            return this.production_bom.sub_parts;
        }
        else{
            return [];
        }*/
    },
  },


  methods: {  

    async addSubBom (production_bom_name){
      // Create the BOM
      const boms = this.$directus.items('production_bom');    
      let new_bom_id = await boms.createOne({
        internal_code: production_bom_name,
      });

      this.addItem(new_bom_id);
    },


    async removeSubBomRelation (production_bom_relation){
      // Remove from the database
      const production_bom_relations = this.$directus.items('production_bom_relation');    
      try {
        await production_bom_relations.deleteOne(production_bom_relation.id);
        // Update the vue
        let index = this.production_bom.sub_parts.indexOf(production_bom_relation);
        if (index>=0){
          this.production_bom.sub_parts.splice(index,1);
        }
      }
      catch{
        alert("Error removing BOM relation with id " + production_bom_relation.id);
      }
    },

    // Excludes all the BOMs that have the current BOMs as a child
    async getChildbleBoms(){
        // TODO: implement an endpoint in the backend to get the list
        const production_boms = this.$directus.items('production_bom');   
        let result = await production_boms.readMany({
            fields:["id","picture","internal_code","description"],
            limit:-1,
        });
//        console.log(result.data);
        return result.data;
    },

    async onMoveProductionBom({ draggedContext, to }) {
//      console.log("Moving production BOM " + draggedContext.element.id + " to " + to.id);
      // TODO: avoid circular inclusion!!
      const production_bom_relations = this.$directus.items('production_bom_relation');    
      await production_bom_relations.updateMany([{
          id: draggedContext.element.id,
          parent: to.id,
        }],
        { 
          fields: ['parent'] 
        }
      );
    },

    async addItem( item_id ){

      // Check if the item is already among the sub parts  
      var i;
      for (i = 0; i < this.production_bom.sub_parts.length; i++) {
        if (item_id == this.production_bom.sub_parts[i].child.id) {
          // Increment the quantity
          this.production_bom.sub_parts[i].quantity++;
          return;
        }
      }

      // Create the new relation
      const production_bom_relations = this.$directus.items('production_bom_relation');    
      let new_sub_part = await production_bom_relations.createOne({
        child: item_id,
        parent: this.production_bom.id,
        quantity: 1,
      });
    
      // Update the view
      const production_boms = this.$directus.items('production_bom');
      new_sub_part.child = await recursiveReadBom(production_boms,new_sub_part.child);
      this.production_bom.sub_parts.push(new_sub_part);
    },

    async updateChildbleBoms () {
     // console.log("updating");
      this.childbleBoms = await this.getChildbleBoms();
    },

  },  

  async mounted() {
    await this.updateChildbleBoms();
  },


}
</script>

<style>

</style>